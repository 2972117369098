@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8" width="100%">
    <h1 style="font-weight: 800; color: #833133">ข้อมูลผู้ใช้งาน</h1>
    <v-row>
      <v-col cols="2"><p style="font-weight: 800">รูปโปรไฟล์</p></v-col>
      <v-col cols="12">
        <v-img :src="profileImg" max-width="200px" class="center"></v-img>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p style="font-weight: 800">ชื่อ</p></v-col>
      <v-col cols="4">
        <span>{{ name }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><p style="font-weight: 800">รหัสผ่าน</p></v-col>
      <v-col cols="4">
        <span>{{ password }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <span>{{ tel }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ตำแหน่ง</p></v-col>
      <v-col cols="4">
        <span>{{ position }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">เลขวิทยุ</p></v-col>
      <v-col cols="4">
        <span>{{ radioCode }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ประเภท</p></v-col>
      <v-col cols="4">
        <span v-if="userType == 'USER'">ประชาชน</span>
        <span v-if="userType == 'POLICE_1'">สายตรวจ</span>
        <span v-if="userType == 'POLICE_2'">ตำรวจจราจร</span>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="pt-5">activeFlag</p></v-col
      >
      <v-col cols="4">
        <v-switch v-model="activeFlag" label="ใช้งาน" disabled></v-switch>
      </v-col>
      <!-- <v-col cols="2"><p style="font-weight:800">idCargImg</p></v-col>
      <v-col cols="4">
        idCargImg
      </v-col>
      <v-col cols="2"><p style="font-weight:800">idCargImg2</p></v-col>
      <v-col cols="4">
        idCargImg2
      </v-col>
      <v-col cols="2"><p style="font-weight:800">activeFlag</p></v-col>
      <v-col cols="4">
        activeFlag
      </v-col> -->
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      activeFlag: "",
      profileImg: "",
      name: "",
      password: "",
      position: "",
      radioCode: "",
      tel: "",
      userType: "",
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.profileImg = userdata.profileImg;
    this.name = userdata.name;
    this.password = userdata.password;
    this.position = userdata.position;
    this.radioCode = userdata.radioCode;
    this.tel = userdata.tel;
    this.userType = userdata.userType;
    this.activeFlag = userdata.activeFlag;
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
  },
};
</script>